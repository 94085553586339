<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Merchant Visitation Log</h1>
      <div class=" flex justify-end items-end">
        <button
          class="uppercase bg-blue-800 py-3 px-6 rounded-md text-white text-sm"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearFilter"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="flex bg-blue-50 p-5">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startdatetime"
          />
        </div>

        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.enddatetime"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedFilteredRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="w-full" style="max-height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="18"
              style="background: #dee6f2"
            >
              Merchant Visitation Log
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            ></th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Business name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Store name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Store ID
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Merchant email
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Location address
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              City/LGA
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              State
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Visitation date
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Visitation status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Classfication
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Terminal at location
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              MSO email
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Visitation checklist
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Inactivity inquiry
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Danger image
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Test Transaction
            </th>
          </tr>
          <tr
            class="text-xs"
            v-for="(location, i) in filterMercahntVisitationLogs"
            :key="i"
          >
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                type="button"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
                @click="
                  $router.push({
                    name: 'ViewMerchantVisitationLog',
                    params: { item: location },
                  })
                "
              >
                View
              </button>
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.businessName || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.visitation.store.name || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.businessId || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.email || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.storeLocation || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.cityLGA || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.state || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{
                moment(location.visitation.visitationCompleteDate).format(
                  "HH:MM DD-MM-YYYY"
                ) || "N/A"
              }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.visitation.visitationStatus || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.classification || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.numberOfTerminals || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ location.visitation.msoEmail || "N/A" }}
            </td>
            <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <span
                v-for="(a, index) in location.visitation.visitationLog
                  .visitationChecklist"
                :key="index"
                >{{ index + " ⚬ " }}</span
              >
            </td> -->
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ true }}
            </td>
            <td class="border px-8 py-3 text-xs">
              {{ location.visitation.visitationLog.inactivityCheck.isActive }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                type="button"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
                @click="
                  handleViewImage(
                    location.visitation.visitationLog.danglerCheck.imageUrl
                  )
                "
              >
                View image
              </button>
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                type="button"
                class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
                @click="
                  handleViewImage(
                    location.visitation.visitationLog.transactionReceiptCheck
                      .imageUrl
                  )
                "
              >
                View image
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="imageDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <img :src="imgSrc" class="img-thumbnail" v-if="imgSrc" />
          <span v-else>No image found</span>
          <button
            @click="imageDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="filterMercahntVisitationLogs.length > 99"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Modal from "./Modal.vue";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      dateData: null,
      imageDiv: false,
      isLoading: false,
      search: "",
      searchActive: false,
      dateFilterActive: false,
      currentPage: 1,
      isDisabled: true,
    };
  },
  components: {
    Modal,
    Loading,
  },
  computed: {
    ...mapGetters(["GET_ALL_VISITATION_LOGS"]),
    filterMercahntVisitationLogs() {
      return this.GET_ALL_VISITATION_LOGS.data;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };

    this.fetchVisitationRecords();
  },
  methods: {
    async fetchVisitationRecords() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        dateFiltered: this.dateFilter,
        searchActive: this.searchActive,
        date: this.dateData,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_MERCHANT_VISITATION_LOG",
          payload
        );
        this.isLoading = false;
        if (res.status == true) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async downloadCSV() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        dateFiltered: this.dateFilter,
        searchActive: this.searchActive,
        date: this.dateData,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_MERCHANT_VISITATION_LOG",
          payload
        );
        this.isLoading = false;
        if (res.status == true) {
          this.isLoading = false;
          window.open(res.data, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async fetchDatedFilteredRecords() {
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        this.currentPage = 1;
        this.dateFilter = true;
        let payload = {
          page: this.currentPage,
          dateFiltered: this.dateFilter,
          searchActive: this.searchActive,
          date: this.dateData,
          search: this.search,
        };
        try {
          let res = await this.$store.dispatch(
            "FETCH_MERCHANT_VISITATION_LOG",
            payload
          );
          this.isLoading = false;
          if (res.status == true) {
            this.isLoading = false;
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {
          this.isLoading = false;
        }
      }
    },
    clearDateFilter() {
      this.currentPage = 1;
      this.dateFilter = false;
      this.resetDate();
      this.fetchVisitationRecords();
    },
    async handleSearch() {
      this.isLoading = true;
      this.currentPage = 1;
      this.searchActive = true;
      let payload = {
        page: this.currentPage,
        dateFiltered: this.dateFilter,
        searchActive: this.searchActive,
        date: this.dateData,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_MERCHANT_VISITATION_LOG",
          payload
        );
        this.isLoading = false;
        if (res.status == true) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    clearFilter() {
      if (this.search !== "") {
        this.search = "";
        this.currentPage = 1;
        this.searchActive = false;
        this.fetchVisitationRecords();
      }
    },
    resetDate() {
      const d = new Date();
      this.dateData = {
        startdatetime: new Date(d.setDate(d.getDate() - 1))
          .toJSON()
          .split("T")[0],
        enddatetime: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },
    handleViewImage(img) {
      this.imageDiv = true;
      this.imgSrc = img;
    },
    async prevPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage - 1,
        dateFiltered: this.dateFilter,
        searchActive: this.searchActive,
        date: this.dateData,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_MERCHANT_VISITATION_LOG",
          payload
        );
        this.isLoading = false;
        if (res.status == true) {
          this.currentPage -= 1;
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage + 1,
        dateFiltered: this.dateFilter,
        searchActive: this.searchActive,
        date: this.dateData,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_MERCHANT_VISITATION_LOG",
          payload
        );
        this.isLoading = false;
        if (res.status == true) {
          this.currentPage += 1;
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.img-thumbnail {
  height: 300px;
  width: 300px;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
